import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import { getToken } from 'hooks/auth/utils';

type KeyParams = {
  [key: string]: any;
};

export const getQueryKey = <T extends KeyParams>(key: string, params?: T) => {
  return [key, ...(params ? [params] : [])];
}

const BASE_URL = 'https://fibrdev.webthink.com/api/'

export const client = axios.create({
  baseURL: BASE_URL,
});

client.interceptors.request.use(
// @ts-ignore
  (config: AxiosRequestConfig) => {
    config.headers = config.headers ?? {};

    const token = getToken();

    if (token) {
      config.headers.Authorization = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
